import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Contact from "../../components/sections/contact"

const Tenant = () => {

    const imageProps = {
        layout:"fullWidth",
        quality:45,
        formats:["auto", "webp", "avif"],
        alt:"",
        placeholder:"none",
        transformOptions:{ fit: 'contain' },
    }

    return(
        <Layout>
        <Seo title="Central@Gurney" />

        {
            typeof window !== 'undefined' && 
            <>
                <div className="container mx-auto">
                    <div className=" border rounded-lg mx-8 mt-24 lg:mt-16 mb-16 py-16">
                        <h3 className="text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-8">
                            Stores
                        </h3>
                        <div className="flex flex-row flex-wrap py-6 gap-12 justify-center items-center">
                            <div className="w-3/5 md:w-56 relative">
                                <Link to="/tenant/winter-warmer" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-1.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-2/5 md:w-36 relative">
                                <Link to="/tenant/the-loft" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-7.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-2/5 md:w-60 relative md:-mx-8">
                                <Link to="/tenant/the-holy-guacamole" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-6-2.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-3/5 md:w-40 relative">
                                <Link to="/tenant/halzan-by-wheelers" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-4-2.png" className="transition-basic  hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-3/5 md:w-48 relative">
                                <Link to="/tenant/antipodean" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-10.png" className="transition-basic hover:opacity-50 cursor-pointer filter invert" {...imageProps}></StaticImage>
                            </div>
                        </div>
                        <div className="flex flex-row flex-wrap py-6 gap-12 justify-center items-center">
                            <div className="w-3/5 md:w-40 relative">
                                <Link to="/tenant/coffee-bean" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-3/5 md:w-52 relative">
                                <Link to="/tenant/kanada-ya" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-5-1.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-3/5 md:w-52 relative">
                                <Link to="/tenant/tree-bar" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-8.png" className="transition-basic hover:opacity-50 filter invert" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-3/5 md:w-24 relative">
                                <Link to="/tenant/miraku" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-3-1.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                            </div>
                            {/* <div className="w-2/5 md:w-36 relative">
                                <Link to="/tenant/richiamo" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-9.png" className="transition-basic filter invert hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                            </div> */}
                            <div className="w-full -mt-16 md:w-96 md:-mx-20 md:mt-0 relative">
                            <Link to="/tenant/minimelts" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-12.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-full md:w-60 -mx-16 relative">
                                <Link to="/tenant/chilis" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-13.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                            </div>
                            <div className="w-2/5 md:w-52 relative">
                                <Link to="/tenant/dipndip" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <StaticImage src="../../images/brand/brand-14.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}></StaticImage>
                            </div>
                        </div>
                        <h3 className="text-[40px] md:text-5xl font-playfair tracking-tight leading-[45px] font-medium text-center mb-8 mt-8">
                            Coming soon
                        </h3>
                        <div className="flex flex-row flex-wrap py-6 gap-4 justify-center items-center">
                            <div className="w-2/5 md:w-52 relative">
                                <Link to="/tenant/dezhuang" className="absolute w-full h-full top-0 right-0 bottom-0 left-0 z-30"/>
                                <img src="/brand-15.png" className="transition-basic hover:opacity-50 cursor-pointer" {...imageProps}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Contact/>
            </>
        }

        </Layout>
    )
}

export default Tenant
